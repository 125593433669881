<template>
  <div>
    <div class="row center-xs">
      <div>
        <h2 class="center-xs main-title">
          {{ $t("How it works?") }}
        </h2>
      </div>
    </div>
    <div class="row center-xs">
      <div>
        <h2 class="center-xs mb60 main-subtitle">
          {{ $t("E-Garderobe makes it easy") }}
        </h2>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs-12 col-lg-3 relative block-container" v-for="(block, index) in blocks" :key="block.title">
        <hr class="absolute block-line hidden-md" :class="{right: index === 0, left: index === 2}">
        <div class="block-number-container">
          <div class="block-number-wrapper">
            <div class="center-xs block-number">
              <p>{{ index + 1 }}</p>
            </div>
          </div>
        </div>
        <h3 class="center-xs block-title">
          {{ $t(block.title) }}
        </h3>
        <p class="center-xs block-description">
          {{ $t(block.description) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      blocks: [
        {
          title: 'Create an account',
          description: 'Enter product details and wait to pass the 1st verification.'
        },
        {
          title: 'Leave it to us',
          description: 'Our team of experts will do all the work to sell your products fast.'
        },
        {
          title: 'Enjoy your earnings',
          description: 'After each sale you will get a payout straight to your account within 14 business days'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-white: color(white);
  $color-black: color(black);

  .main-title {
    font-size: 35px;
    font-weight: 400;
    margin-top: 50px;
    margin-bottom: 15px;
    @media (max-width: 992px) {
      font-size: 22px;
      margin-bottom: 0;
    }
  }
  .main-subtitle {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Poppins';
    text-transform: uppercase;
    letter-spacing: 1.26px;
    @media (max-width: 992px) {
      font-size: 14px;
      inline-size: 216px;
      letter-spacing: 1.04px;
      margin-bottom: 25px;
    }
  }
  .block-container {
    margin-bottom: 30px;
  }
  .block-title {
    font-size: 12px;
    font-weight: bold;
    font-family: 'Poppins';
    text-transform: uppercase;
    letter-spacing: 1.26px;
    @media (max-width: 992px) {
      font-size: 14px;
      letter-spacing: 1.04px;
    }
  }
  .block-description {
    font-size: 12px;
    box-sizing: border-box;
    margin: auto;
    inline-size: 267px;
  }
  .block-number-container {
    display: flex;
    justify-content: center;
  }
  .block-number {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;
    font-size: 12px;
    border-radius: 50%;
    color: $color-white;
    background-color: $color-black;
  }
  .block-number-wrapper {
    background-color: white;
    padding: 0 55px;
  }
  .block-line {
    top: 1px;
    left: 0;
    right: 0;
    z-index: -10;
    width: 100%;
    background-color: $color-black;
    &.right {
      width: 50%;
      right: 0;
      left: unset;
    }
    &.left {
      width: 50%;
      right: unset;
      left: 0
    }
  }
</style>
